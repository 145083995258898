import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Button, Dropdown, Image, Grid, Badge } from "antd";
import {
  HomeOutlined,
  InfoCircleOutlined,
  AppstoreOutlined,
  ToolOutlined,
  PhoneOutlined,
  FileSearchOutlined,
  SunOutlined,
  MessageOutlined,
  GlobalOutlined,
  MenuOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import "../styles/Header.css";
import logo from "../images/logo.png";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const HeaderComponent = ({
  translatedTexts,
  textsToTranslate,
  showDrawer,
  setLanguage,
  languageMenu,
}) => {
  const screens = useBreakpoint();
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    {
      key: "1",
      label: (
        <Link to="/">
          <HomeOutlined style={{ marginRight: 8 }} />
          {translatedTexts.home || textsToTranslate.home}
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/about">
          <InfoCircleOutlined style={{ marginRight: 8 }} />
          {translatedTexts.about || textsToTranslate.about}
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/products">
          <AppstoreOutlined style={{ marginRight: 8 }} />
          {translatedTexts.products || textsToTranslate.products}
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/services">
          <ToolOutlined style={{ marginRight: 8 }} />
          {translatedTexts.services || textsToTranslate.services}
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to="/contact">
          <PhoneOutlined style={{ marginRight: 8 }} />
          {translatedTexts.contact || textsToTranslate.contact}
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to="/quotation">
          <FileSearchOutlined style={{ marginRight: 8 }} />
          {translatedTexts.getQuote || textsToTranslate.getQuote}
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link to="/solar-calculation">
          <SunOutlined style={{ marginRight: 8 }} />
          {translatedTexts.getSolarCalculation ||
            textsToTranslate.getSolarCalculation}
        </Link>
      ),
    },
    {
      key: "8",
      label: (
        <Link to="/chat" style={{ paddingRight: 8 }}>
          <MessageOutlined style={{ marginRight: 8 }} />
          {translatedTexts.liveChat || textsToTranslate.liveChat}
          <Badge dot offset={[-5, -12]}></Badge>
        </Link>
      ),
    },
  ];

  return (
    <Header
      className="main-header"
      style={{
        position: isHomePage ? (isScrolled ? "sticky" : "absolute") : "sticky",
        inset: isHomePage ? (isScrolled ? "0px" : "10px") : "0",
        backgroundColor: isHomePage
          ? isScrolled
            ? "rgb(0, 21, 41)"
            : "rgba(255, 255, 255, 0.69)"
          : "rgb(0, 21, 41)",
        margin: isHomePage ? (isScrolled ? 0 : "20px auto") : 0,
        maxWidth: isHomePage
          ? isScrolled
            ? "100%"
            : "calc(100% - 40px)"
          : "100%",
        borderRadius: isHomePage ? (isScrolled ? 0 : "15px") : 0,
        border: isHomePage
          ? isScrolled
            ? "none"
            : "1px solid rgba(255, 255, 255, 0.3)"
          : "none",
      }}
    >
      <div className="logo-container">
        <Image
          width={isHomePage ? (isScrolled ? 80 : 100) : 80}
          src={logo}
          preview={false}
          className="header-logo"
        />
      </div>

      {screens.md ? (
        <div className="desktop-menu-container">
          <Menu
            mode="horizontal"
            items={menuItems}
            overflowedIndicator={<MoreOutlined style={{ fontSize: 18 }} />}
            theme={isHomePage ? (isScrolled ? "dark" : "light") : "dark"}
            className="main-menu"
            style={{
              color:
                isHomePage && !isScrolled ? "rgba(0, 0, 0, 0.85)" : "white",
            }}
          />
          <Dropdown overlay={React.cloneElement(languageMenu, { isMobile: false })} trigger={["click"]}>
            <Button
              shape="circle"
              icon={
                <GlobalOutlined
                  style={{
                    fontSize: 18,
                    color:
                      isHomePage && !isScrolled
                        ? "rgba(0, 0, 0, 0.85)"
                        : "white",
                  }}
                />
              }
              className="language-button"
              style={{
                border: isHomePage
                  ? isScrolled
                    ? "1px solid rgba(255, 255, 255, 0.1)"
                    : "1px solid rgba(0, 0, 0, 0.1)"
                  : "1px solid rgba(255, 255, 255, 0.1)",
                background: isHomePage
                  ? isScrolled
                    ? "rgba(255, 255, 255, 0.1)"
                    : "rgba(255, 255, 255, 0.5)"
                  : "rgba(255, 255, 255, 0.1)",
              }}
            />
          </Dropdown>
        </div>
      ) : (
        <div className="mobile-menu-container">
          <Dropdown overlay={React.cloneElement(languageMenu, { isMobile: true })} trigger={["click"]}>
            <Button
              shape="circle"
              icon={
                <GlobalOutlined
                  style={{
                    fontSize: 18,
                    color:
                      isHomePage && !isScrolled
                        ? "rgba(0, 0, 0, 0.85)"
                        : "white",
                  }}
                />
              }
              className="language-button"
              style={{
                border: isHomePage
                  ? isScrolled
                    ? "1px solid rgba(255, 255, 255, 0.1)"
                    : "1px solid rgba(0, 0, 0, 0.1)"
                  : "1px solid rgba(255, 255, 255, 0.1)",
                background: isHomePage
                  ? isScrolled
                    ? "rgba(255, 255, 255, 0.1)"
                    : "rgba(255, 255, 255, 0.5)"
                  : "rgba(255, 255, 255, 0.1)",
              }}
            />
          </Dropdown>
          <Button
            type="text"
            icon={
              <MenuOutlined
                style={{
                  fontSize: 24,
                  color:
                    isHomePage && !isScrolled ? "rgba(0, 0, 0, 0.85)" : "white",
                }}
              />
            }
            onClick={showDrawer}
            className="menu-button"
          />
        </div>
      )}
    </Header>
  );
};

export default HeaderComponent;