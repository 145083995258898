import React, { useState, useEffect } from "react";
import {
  Carousel,
  Card,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  message,
  Skeleton,
  Modal,
} from "antd";
import { db, collection, getDocs } from "../pages/firebase";
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { submitQuotation } from "../utils/api";
import "../styles/SliderCarousel.css";
import translateText from "../translationService";

const { Option } = Select;

const SliderCarousel = ({ language = "en" }) => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const [inputForm] = Form.useForm();
  const [isMobile, setIsMobile] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [labels, setLabels] = useState({
    getQuotation: "Get a Quotation",
    name: "Name",
    email: "Email",
    phone: "Phone Number",
    location: "Location",
    product: "Product",
    installation_date: "Preferred Installation Date",
    message: "Additional Message",
    submit: "Get Quote",
    solar_panel: "Solar Panel",
    battery: "Battery",
    inverter: "Inverter",
    accessory: "Accessory",
  });

  useEffect(() => {
    fetchCarouselItems();
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    translateLabels();
  }, [language]);

  const handleResize = () => setIsMobile(window.innerWidth <= 768);

  const fetchCarouselItems = async () => {
    setLoading1(true);
    try {
      const querySnapshot = await getDocs(collection(db, "carouselItems"));
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const sortedItems = items.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setCarouselItems(sortedItems);
    } catch (error) {
      console.error("Error fetching carousel items:", error);
    } finally {
      setLoading1(false);
    }
  };

  const translateLabels = async () => {
    setLoading1(true);
    const translatedLabels = {};
    for (const [key, value] of Object.entries(labels)) {
      translatedLabels[key] = await translateText(value, language);
    }
    setLabels(translatedLabels);
    setLoading1(false);
  };

  const onFinish = async (values) => {
    inputForm.resetFields();
    setLoading(true);
    try {
      const formattedValues = {
        ...values,
        installation_date: new Date(),
      };
      await submitQuotation(formattedValues);
      message.success("Quotation submitted successfully");
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error submitting quotation:", error);
      message.error("Failed to submit quotation");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card bordered={false} className="professional-carousel">
      <div className="carousel-container">
        {loading1 ? (
          <div className="skeleton-carousel">
            <Skeleton.Image active className="skeleton-image" />
            <Skeleton active paragraph={{ rows: 2 }} />
          </div>
        ) : (
          <>
            <Carousel autoplay effect="fade" className="hero-carousel">
              {carouselItems.map((item, index) => (
                <div key={index} className="carousel-slide">
                  <img
                    src={
                      isMobile && item.mobileImageUrl
                        ? item.mobileImageUrl
                        : item.desktopImageUrl
                    }
                    alt={
                      isMobile && item.mobileAltText
                        ? item.mobileAltText
                        : item.desktopAltText
                    }
                    className="carousel-image"
                  />
                  <div
                    className={`animated-caption ${isMobile ? "mobile" : ""}`}
                  >
                    <h3 className="caption-title">
                      {isMobile && item.mobileCaption
                        ? item.mobileCaption
                        : item.desktopCaption}
                    </h3>
                  </div>
                </div>
              ))}
            </Carousel>

            {!isMobile && (
              <div className="floating-form-container">
                <div className="form-entrance-animation">
                  {renderCompactForm()}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {isMobile && (
        <Button
          type="primary"
          className="mobile-cta-button"
          onClick={() => setIsModalVisible(true)}
          block
        >
          {labels.getQuotation}
        </Button>
      )}

      <Modal
        title={labels.getQuotation}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className="mobile-form-modal"
      >
        {renderForm()}
      </Modal>
    </Card>
  );

  function renderCompactForm() {
    return (
      <div className="compact-quote-form">
        <h3 className="form-title">{labels.getQuotation}</h3>
        <Form form={inputForm} layout="vertical" onFinish={onFinish}>
          <Form.Item name="name" rules={[{ required: true }]}>
            <Input prefix={<UserOutlined />} placeholder={labels.name} />
          </Form.Item>

          <Form.Item name="email" rules={[{ required: true, type: "email" }]}>
            <Input prefix={<MailOutlined />} placeholder={labels.email} />
          </Form.Item>

          <Form.Item name="phone" rules={[{ required: true }]}>
            <Input prefix={<PhoneOutlined />} placeholder={labels.phone} />
          </Form.Item>

          <Form.Item name="location" rules={[{ required: true }]}>
            <Input
              prefix={<EnvironmentOutlined />}
              placeholder={labels.location}
            />
          </Form.Item>

          <Form.Item name="product" rules={[{ required: true }]}>
            <Select placeholder={labels.product}>
              <Option value="solar_panel">{labels.solar_panel}</Option>
              <Option value="battery">{labels.battery}</Option>
              <Option value="inverter">{labels.inverter}</Option>
              <Option value="accessory">{labels.accessory}</Option>
            </Select>
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            {labels.submit}
          </Button>
        </Form>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="full-quote-form">
        <Form form={inputForm} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label={labels.name}
            rules={[{ required: true }]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item
            name="email"
            label={labels.email}
            rules={[{ required: true, type: "email" }]}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>

          <Form.Item
            name="phone"
            label={labels.phone}
            rules={[{ required: true }]}
          >
            <Input prefix={<PhoneOutlined />} />
          </Form.Item>

          <Form.Item
            name="location"
            label={labels.location}
            rules={[{ required: true }]}
          >
            <Input prefix={<EnvironmentOutlined />} />
          </Form.Item>

          <Form.Item
            name="product"
            label={labels.product}
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="solar_panel">{labels.solar_panel}</Option>
              <Option value="battery">{labels.battery}</Option>
              <Option value="inverter">{labels.inverter}</Option>
              <Option value="accessory">{labels.accessory}</Option>
            </Select>
          </Form.Item>

          <Form.Item name="installation_date" label={labels.installation_date}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="message" label={labels.message}>
            <Input.TextArea rows={3} />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading} block>
            {labels.submit}
          </Button>
        </Form>
      </div>
    );
  }
};

export default SliderCarousel;
