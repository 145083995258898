import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Popconfirm,
  message,
  Tabs,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  db,
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "../pages/firebase";

const CarouselEditor = () => {
  const [form] = Form.useForm();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [desktopFileList, setDesktopFileList] = useState([]);
  const [mobileFileList, setMobileFileList] = useState([]);

  useEffect(() => {
    fetchCarouselItems();
  }, []);

  const fetchCarouselItems = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "carouselItems"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setItems(data);
    } catch (error) {
      message.error("Failed to fetch items");
    }
    setLoading(false);
  };

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      let desktopImageUrl = editingItem?.desktopImageUrl || "";
      let mobileImageUrl = editingItem?.mobileImageUrl || "";

      // Upload desktop image
      if (desktopFileList.length > 0) {
        const desktopFile = desktopFileList[0].originFileObj;
        const desktopRef = ref(
          storage,
          `carousel/desktop/${Date.now()}_${desktopFile.name}`
        );
        await uploadBytes(desktopRef, desktopFile);
        desktopImageUrl = await getDownloadURL(desktopRef);
      }

      // Upload mobile image
      if (mobileFileList.length > 0) {
        const mobileFile = mobileFileList[0].originFileObj;
        const mobileRef = ref(
          storage,
          `carousel/mobile/${Date.now()}_${mobileFile.name}`
        );
        await uploadBytes(mobileRef, mobileFile);
        mobileImageUrl = await getDownloadURL(mobileRef);
      }

      const itemData = {
        ...values,
        desktopImageUrl,
        mobileImageUrl,
        createdAt: editingItem?.createdAt || new Date().toISOString(),
      };

      if (editingItem) {
        await updateDoc(doc(db, "carouselItems", editingItem.id), itemData);
        message.success("Item updated");
      } else {
        await addDoc(collection(db, "carouselItems"), itemData);
        message.success("Item added");
      }

      form.resetFields();
      setDesktopFileList([]);
      setMobileFileList([]);
      setEditingItem(null);
      setModalVisible(false);

      setModalVisible(false);
      await fetchCarouselItems();
    } catch (error) {
      message.error("Error saving item");
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    form.resetFields();
    setDesktopFileList([]);
    setMobileFileList([]);
    setEditingItem(null);
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Desktop Preview",
      dataIndex: "desktopImageUrl",
      render: (url) => <img src={url} alt="desktop" style={{ width: 100 }} />,
    },
    {
      title: "Mobile Preview",
      dataIndex: "mobileImageUrl",
      render: (url) => <img src={url} alt="mobile" style={{ width: 50 }} />,
    },
    {
      title: "Desktop Caption",
      dataIndex: "desktopCaption",
    },
    {
      title: "Mobile Caption",
      dataIndex: "mobileCaption",
    },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
          />
          <Popconfirm
            title="Delete item?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const showEditModal = (item) => {
    form.setFieldsValue(item);
    setEditingItem(item);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, "carouselItems", id));
      await fetchCarouselItems();
      message.success("Item deleted");
    } catch (error) {
      message.error("Delete failed");
    }
    setLoading(false);
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        icon={<PlusOutlined />}
      >
        Add New Slide
      </Button>

      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        loading={loading}
        style={{ marginTop: 16 }}
      />

      <Modal
        title={`${editingItem ? "Edit" : "New"} Carousel Item`}
        visible={modalVisible}
        onCancel={() => handleModalClose()}
        footer={null}
        width={800}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Desktop" key="1">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Desktop Image">
                    <Upload
                      listType="picture-card"
                      beforeUpload={() => false}
                      fileList={desktopFileList}
                      onChange={({ fileList }) => setDesktopFileList(fileList)}
                    >
                      {desktopFileList.length < 1 && "+ Upload"}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Desktop Caption" name="desktopCaption">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item label="Desktop Alt Text" name="desktopAltText">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Mobile" key="2">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Mobile Image">
                    <Upload
                      listType="picture-card"
                      beforeUpload={() => false}
                      fileList={mobileFileList}
                      onChange={({ fileList }) => setMobileFileList(fileList)}
                    >
                      {mobileFileList.length < 1 && "+ Upload"}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mobile Caption" name="mobileCaption">
                    <Input.TextArea rows={4} />
                  </Form.Item>
                  <Form.Item label="Mobile Alt Text" name="mobileAltText">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editingItem ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CarouselEditor;
