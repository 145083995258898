import React from "react";
import { Menu } from "antd";

const LanguageMenu = ({ setLanguage, closeDrawer, isMobile }) => (
  <Menu>
    <Menu.Item
      key="1"
      onClick={() => {
        setLanguage("en");
        if (isMobile) closeDrawer();
      }}
    >
      English
    </Menu.Item>
    <Menu.Item
      key="2"
      onClick={() => {
        setLanguage("es");
        if (isMobile) closeDrawer();
      }}
    >
      Español
    </Menu.Item>
    <Menu.Item
      key="3"
      onClick={() => {
        setLanguage("zh");
        if (isMobile) closeDrawer();
      }}
    >
      中文
    </Menu.Item>
    <Menu.Item
      key="4"
      onClick={() => {
        setLanguage("ar");
        if (isMobile) closeDrawer();
      }}
    >
      العربية
    </Menu.Item>
    <Menu.Item
      key="5"
      onClick={() => {
        setLanguage("it");
        if (isMobile) closeDrawer();
      }}
    >
      Italiano
    </Menu.Item>
  </Menu>
);

export default LanguageMenu;
